import React, { useState } from 'react'
import Button from '../../Core/Button'
import Text from '../../Core/Text'
import Input from '../../Core/Input'
import {
  selectSfResidencesList,
  sfResidencesInit,
} from '../../../state/salesforceResidences'
import { useSelector } from 'react-redux'
import api, { PopinFormState } from '../../../utils/api'
import { useIntl } from '../../../../plugins/gatsby-plugin-intl-kley'
import useGtmEvent from '../../../hooks/useGtmEvent'
import ModalLegalText from '../../ModalLegalText'
import { useTranslation } from '../../../hooks/useTranslation'
import useCustomLocale from '../../../hooks/useCustomLocale'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'

const Summer = (
  props: {
    data: any
    className?: string
  },
  ref: any
) => {
  const { data, className = '' } = props
  const { t } = useTranslation('common')
  const [currentStep, setCurrentStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [formState, setFormState] = useState<PopinFormState>({
    typeDemande: 'Louer un logement sur plusieurs mois ou années',
  })
  const [hasError, setHasError] = useState(false)
  const sfResidences: any[] = useSelector(selectSfResidencesList)
  const sfResidencesId = sfResidences.filter(
    (residence) => residence.Residence__c === data.salesforceName
  )
  const titles = [data.step1Title, data.step2Title, data.step3Title]
  const ctas = [data.step1Cta, data.step2Cta, data.step3Cta]
  const { locale, formatMessage } = useIntl()
  const [formWasSubmitted, setFormWasSubmitted] = useState(false)

  const [phoneNumber, setPhoneNumber] = useState()
  const [phoneNumberError, setPhoneNumberError] = useState('')
  const currLocale = useCustomLocale()

  const handleChangeField = (fieldName: string, fieldVal: any) => {
    setFormState(
      Object.assign({}, formState, {
        [fieldName]: fieldVal,
      })
    )
  }

  const handleNextStep = (e: any) => {
    e.preventDefault()
    setCurrentStep((prevStep) => prevStep + 1)
    // const $container = document.getElementById('landing-form')
    // window.scrollTo({
    //   top: $container?.offsetTop - 100,
    //   behavior: 'smooth',
    // })
  }

  /*
  const handlePrevStep = (e: any) => {
    e.preventDefault()
    setCurrentStep((prevStep) => prevStep - 1);
  };
  */

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setHasError(false)
    let formData: PopinFormState = formState
    if (sfResidencesId.length > 0) {
      const foundResidence = sfResidencesId[0]
      formData = Object.assign({}, formData, {
        residence: {
          id: foundResidence.AD_ResidenceId__c,
          name: foundResidence.Residence__c,
          filialeCode: foundResidence.FilialeCode__c,
        },
      })
    } else if (
      data?.linkedResidence?.salesforceName &&
      data?.linkedResidence?.salesforceId
    ) {
      formData = Object.assign({}, formData, {
        residence: {
          id: data?.linkedResidence?.salesforceId,
          name: data?.linkedResidence?.salesforceName,
        },
      })
    }

    const guserIdFromDatalayer = window.dataLayer?.find(
      (item: any) => item.event === 'gtm.js'
    )?.['gtm.start']

    formData = Object.assign({}, formData, {
      gclientId: '',
      gclid: '',
      gtrackingId: '',
      guserId: guserIdFromDatalayer || '',
      formOrigin: "B2C-Demande d'hébergement ou de service",
      natureDemandeur: 'Particulier',
      language: locale === 'fr' ? 'Français' : 'Anglais',
    })

    // Do not send TypedeDemande__c for B2B form
    delete formData.serviceToContact

    setIsLoading(true)
    api
      .createLead(formData)
      .then((res) => res.json())
      .then((res) => {
        if (res && res.data) {
          if ((res.status === 200 || res.status === 201) && res.data.success) {
            /*
              useGtmEvent('formulaire_envoye', {
                objet: formData.typeDemande,
                residence: formData.residence?.name,
                type: 'B2C',
              })
              */

            setFormState({})
            setFormWasSubmitted(true)
            setCurrentStep(3)
          } else {
            throw res
          }
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        setHasError(true)
        console.log('Error creating salesforce lead:', e.status, e.data)
      })
  }

  return (
    <section ref={ref} id={'landing-form'} className={`form ${className}`}>
      <form
        method="post"
        onSubmit={currentStep === 2 ? handleSubmit : handleNextStep}
        className={'max-w-[1131px] mx-auto'}
      >
        <div
          className={`px-10 lg:flex lg:col-2 lg:px-0 ${
            currentStep === 1 ? 'justify-between' : 'justify-center'
          }`}
        >
          {currentStep === 1 && (
            <div className={'max-w-[365px] w-full'}>
              {data.formTitle && (
                <Text tag="p" as="h3" className={`c-bleu-fonce`}>
                  {data.formTitle}
                </Text>
              )}
              {data.formDescription && (
                <Text as="bodyH1" className="c-bleu-fonce mt-5">
                  {data.formDescription}
                </Text>
              )}
            </div>
          )}
          <div
            className={`${
              currentStep === 1 ? 'lg:pl-[8.2%]' : 'text-center max-w-[626px]'
            } mt-6 lg:mt-0`}
          >
            <div
              className={`step-items flex items-center ${
                currentStep === 1 ? '' : 'justify-center'
              } `}
            >
              {Array.from({ length: 2 }, (_, i) => {
                const stepNumber = i + 1
                return (
                  <React.Fragment key={`step-${i}`}>
                    <Text
                      as="captionH3"
                      tag={'div'}
                      className={`step-item rounded-full w-9 h-9 flex items-center justify-center border font-bold ${
                        currentStep === stepNumber
                          ? 'bg-typo-title border-[#F5AAA2]'
                          : 'border-[#21534F]'
                      }`}
                    >
                      0{stepNumber}
                    </Text>
                    {i === 0 && (
                      <span
                        className={
                          'grow max-w-[122px] border-b border-[#21534F] mx-4'
                        }
                      ></span>
                    )}
                  </React.Fragment>
                )
              })}
            </div>
            {titles[currentStep - 1] && (
              <Text as="h6" className="c-bleu-fonce mt-6 lg:mt-16">
                {titles[currentStep - 1]}
              </Text>
            )}
            <div className={'mt-6'}>
              {sfResidencesId && sfResidencesId[0] && (
                <input
                  type="hidden"
                  value={sfResidencesId[0].Id}
                  name="residence"
                />
              )}
              {currentStep === 1 && (
                <div className="lg:grid grid-cols-2 gap-4">
                  <Input
                    label={`${t('b2b name', 'common')} *`}
                    name="firstName"
                    required
                    maxLength={40}
                    onChange={handleChangeField}
                  />
                  <Input
                    label={`${t('b2b lastname', 'common')} *`}
                    name="lastName"
                    required
                    maxLength={80}
                    onChange={handleChangeField}
                  />
                </div>
              )}
              {currentStep === 2 && (
                <div>
                  <Input
                    label={`${t('b2b email', 'common')} *`}
                    name="email"
                    type={'email'}
                    required
                    onChange={handleChangeField}
                  />
                  <Input
                     label={`${t('b2b number', 'common')} *`}
                    name="phone"
                    type={'tel'}
                    required
                    onChange={handleChangeField}
                  />
                </div>
              )}
              {currentStep === 3 && (
                <Text as="bodyH1" className="c-bleu-fonce mt-5">
                 {
                  data?.successText
                 }
                </Text>
              )}
            </div>
            {currentStep !== 3 && (
              <div>
                <ModalLegalText data={data}/>
                <div className="mt-10">
                  <Button btnType="dark">{ctas[currentStep - 1]}</Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </section>
  )
}

export default React.forwardRef(Summer)
